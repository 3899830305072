<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="far fa-credit-card"></i> ข้อมูลการชำระเงิน </h2>
            </b-col>
          </b-row>
        </div>
        <div class="card-body">
          <page-payment />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import  pagePayment from "./view/page.payment.vue";

  export default {
    name: 'payment-index',
    components: {
      pagePayment
    }
  };
</script>
<style>

</style>
