<template>
  <div>
    <div class="card mb-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-3 mb-4">
            <b-dropdown id="dropdown-1" text="ร้านค้า" class="dropdown_input_search mb-1" @show="getFilter">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li v-for="v in criteria.storeFilters" :key="v.id" style="padding: .5rem 1rem; font-size: 0.875rem;" >
                    <input
                      type="checkbox"
                      class="checkbox_search_input"
                      :name="`checkbox_store_filters${v.id}`"
                      :id="`checkbox_store_filters${v.id}`"
                      v-model="v.selected"
                      @change="searchHandler()"
                    />
                    <label class="form-check-label" v-bind:for="`checkbox_store_filters${v.id}`" >{{ v.name }} ({{ v.filter_count }})</label>
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="สถานะการจ่าย" class="dropdown_input_search ml-1 mb-1" @show="getFilter">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
                <ul style="list-style-type: none; padding-left: 0px;">
                  <li
                    v-for="v in criteria.statusFilters"
                    :key="v.id"
                    style="padding: .5rem 1rem; font-size: 0.875rem;"
                  >
                    <input
                      type="checkbox"
                      class="checkbox_search_input"
                      :name="`checkbox_payment_status${v.id}`"
                      :id="`checkbox_payment_status${v.id}`"
                      v-model="v.selected"
                      @change="searchHandler()"
                    />
                    <label
                      class="form-check-label"
                      v-bind:for="`checkbox_payment_status${v.id}`"
                      >{{ v.name }} ({{ v.filter_count }})</label
                    >
                  </li>
                </ul>
              </b-dropdown-text>
            </b-dropdown>
          </div>
          <div class="col-sm-2">
            <date-picker
              :format="'DD/MM/YYYY'"
              v-model="criteria.orderDate"
              class="w-100"
              input-class="form-control"
              placeholder="วันที่สั่งซื้อ"
              :append-to-body="false"
              :popup-style="{ left: 0 }"
            ></date-picker>
          </div>
          <div class="col-sm-2">
            <date-picker
              :format="'DD/MM/YYYY'"
              v-model="criteria.deliveryDate"
              class="w-100"
              input-class="form-control"
              placeholder="วันที่จัดส่ง"
              :append-to-body="false"
              :popup-style="{ left: 0 }"
            ></date-picker>
          </div>
          <div class="col-sm-2">
            <date-picker
              :format="'DD/MM/YYYY'"
              v-model="criteria.paymentDate"
              class="w-100"
              input-class="form-control"
              placeholder="วันที่ชำระเงิน"
              :append-to-body="false"
              :popup-style="{ left: 0 }"
            ></date-picker>
          </div>
          <div class="col-sm-3">
            <b-input-group>
              <b-input-group-append>
                <b-input-group-text class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input
                placeholder="ระบุการค้นหา เช่น รหัสลูกค้า ชื่อลูกค้า สถานะการสั่งซื้อ สถานะการจ่าย"
                v-model="criteria.search"
                class="form-control-group"
                @keyup.enter.native="searchHandler()"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button
                  size="md"
                  text="Button"
                  variant="primary"
                  @click="searchHandler()"
                  class="btn_border_radius_right_top_bt"
                  style="width: 86px;"
                >
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12">
            <hr class="mt-5 mb-5" />
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2" >
              จำนวน:
              <strong style="color: #000000; font-weight: bold;"
                >{{ countFilter() }} รายการ</strong
              >
              จาก {{ paging.rowTotal | numberIntFormat }} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.storeFilters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`code${index}`" v-if="select.selected" >
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{
                  select.filter_count
                }}</span>
                <span
                  class="ml-3"
                  style="font-size: 8px; color: #1C9EE7;"
                  @click="unSelectFilter(select)"
                >
                  <i
                    class="fa fa-times icon-del"
                    style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"
                  ></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.statusFilters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`size${index}`"  v-if="select.selected">
                {{ select.name }}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()" >ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-4 text-right">
      <b-button
        size="md"
        variant="primary"
        :to="'/payment/add'"
        class="style_btn"
        v-if="permission.actionData.flag_insert"
      >
        <i class="fa fa-plus"></i>
      </b-button>
    </div>
    <div class="card custom-card">
      <div class="card-body">
        <div class="table-responsive padding_top_20">
          <table class="table align-middle table-bordered">
            <thead>
              <tr class="text-center">
                <!-- <th style="min-width: 50px; width: 1px;">เลือก</th> -->
                <th>รหัสการชำระเงิน</th>
                <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                <th>วันที่ชำระเงิน</th>
                <th>วันที่สั่งซื้อ</th>
                <th>หมายเลขออเดอร์</th>
                <th>ใบเสร็จ</th>
                <th>ร้านค้า</th>
                <th>ลูกค้า</th>
                <th>เบอร์</th>
                <th>Line</th>
                <th>Email</th>
                <th>หมายเลขอ้างอิง</th>
                <th>สถานะการชำระเงิน</th>
                <th>รูปแบบการจ่ายเงิน</th>
                <!-- <th>ภาพหลักฐาน</th>
                <th>หลังอัพโหลด</th>-->
                <th>จำนวนเงินทั้งหมด</th>
                <th>จำนวนเงินที่จ่าย</th>
                <th>จำนวนเงินคงเหลือ</th>
              </tr>
            </thead>
            <tbody v-if="!onSearching">
              <tr v-for="(data, index) in items" :key="`list_payment_${index}`">
                <td class="text-center">{{ data.code }}</td>

                <td class="text-center">
                  <span
                    class="style_icon_btn"
                    v-if="permission.actionData.flag_update"
                  >
                    <i
                      class="fas fa-pen"
                      @click="onEditHandler(data.id)"
                      title="แก้ไข"
                    ></i>
                  </span>
                  <span
                    class="style_icon_btn"
                    v-if="data.status != 3 && permission.actionData.flag_update"
                  >
                    <i
                      class="fas fa-check-circle"
                      @click="onConfirmHander(data)"
                      title="ยืนยันการชำระเงิน"
                    ></i>
                  </span>
                  <span class="style_icon_btn">
                    <i
                      class="fas fa-exclamation-circle"
                      title="แจ้งปัญหา"
                      @click="onIssueHandler(data)"
                    ></i>
                  </span>
                </td>
                <td class="text-center">{{ new Date(data.payment).toLocaleString() }}</td>
                <td class="text-center">{{ data.date_order }}</td>
                
                <td class="text-center">{{ data.order_code }}</td>
                <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class="'fas fa-print text-primary'" :title="2" @click="onDownloadReceipt(data)"  ></i>
                    </span>
                  </td>
                <td class="text-center">{{ data.store_name }}</td>

                <td class="text-center">{{ data.customer_name }}</td>
                <td class="text-center">{{ data.phone }}</td>
                <td class="text-center">{{ data.line }}</td>
                <td class="text-center">{{ data.email }}</td>
                <td class="text-center">{{ data.ref }}</td>
                <td class="text-center">
                  <span class="style_icon_btn">
                    <i
                      :class="StringUtils.getIconPaymentStatus(data.status)"
                      :title="
                        StringUtils.getTextPaymentStatus(data.status).text
                      "
                    ></i>
                  </span>
                </td>
                <td class="text-center">
                  <!-- <span class="text-primary" v-if="data.type == 2" @click="clickCredit">
                    <img :src="`/img/2C2P-Logo.png`" style="width: 50px;" />
                  </span> -->
                  <span>{{
                    StringUtils.getTextPaymentType(data.type).text
                  }}</span>
                </td>
                <!--<td class="text-center">
                  <img :src="data.upload ? data.upload : funcs.isImgEmpty(data.upload)" style="width: 30px;" />
                </td>
                <td class="text-center">
                  <img :src="data.uploaded ? data.uploaded : funcs.isImgEmpty(data.uploaded)" style="width: 30px;" />
                </td> -->
                <td class="text-right">
                  {{ data.amount  }}
                </td>
                 <td class="text-right">
                  {{ data.paid }}
                </td>
                 <td class="text-right">
                  {{ data.remain }}
                </td>
              </tr>
              <tr v-if="items.length === 0 && !onSearching" class="text-center">
                <td colspan="12">ไม่มีข้อมูล</td>
              </tr>              
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="12" class="text-center">
                  <div class="spinner"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="card mb-4 mt-4 custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">
            <h3>สถานะการชำระเงิน</h3>
            <div
              v-for="(v, index) in payment_status"
              :key="`list_payment_status_${index}`"
            >
              <i :class="StringUtils.getIconPaymentStatus(v.id)"></i>
              {{ v.id }} "{{ v.text }}"
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row padding_top_20">
      <div class="col-lg-1 col-md-2 col-3" style="margin-top: 30px;">
        <select
          class="form-control mb-2"
          id="pageLimit"
          style="width: 80px;"
          v-model="paging.limit"
          v-on:change="changeLimit()"
        >
          <option v-for="data in paging.pages" :key="data" :value="data">
            {{ data }}
          </option>
        </select>
      </div>
      <div class="col-lg-11">
        <div class="row g-3" v-if="paging.rowTotal > 0">
          <div
            class="col-xl-11 col-lg-10 mb-2 text-right"
            style="margin-top: 30px;"
          >
            <label
              class="form-label"
              style="margin: 8px; font-size: 12px;font-family: Sarabun-Light;color: #818181;"
            >
              แสดงรายการที่
              <strong
                >{{ (paging.page - 1) * paging.limit + 1 }} ถึง
                {{ paging.page * paging.limit }}</strong
              >
              จาก
              <strong>{{ paging.rowTotal  }} รายการ </strong>
            </label>
            <paginate
              v-model="paging.page"
              :first-last-button="true"
              :page-count="paging.pageTotal"
              :page-range="5"
              :margin-pages="2"
              :prev-text="'&lt;'"
              :next-text="'&gt;'"
              :first-button-text="'&verbar;&lt;'"
              :last-button-text="'&gt;&verbar;'"
              :click-handler="changePage"
              :container-class="
                'pagination pagination-sm float-right m-auto paging_ul'
              "
              :page-class="'page-link'"
              :prev-class="'page-link prev_paging'"
              :next-class="'page-link next_paging'"
            >
            </paginate>
          </div>
          <div class="col-xl-1 col-lg-2 padding_left_none">
            <label class="form-label" style="font-size: 12px;font-family: Sarabun-Light;">
              Go to page
            </label>
            <Select2
              v-model="paging.page"
              :options="paging.pageSizes"
              @change="changePage(paging.page)"
            />
          </div>
        </div>
      </div>
    </div>

    <dialogIssue ref="dialogIssue"/>
    <form
      action="https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/PaymentAuth.aspx"
      method="POST"
      name="paymentRequestForm"
      target="_blank"
    >
      <input type="hidden" name="paymentRequest" value="" />
    </form>
  </div>
</template>
<script>
import $ from "jquery";
import dialogIssue from "../../issue/view/dialog.issue";
import Constants from "../../../util/constants";
import StringUtils from "../../../util/StringUtils";
import Config from "../../../util/config";
import DateUtils from "../../../util/dateUtils";
export default {
  name: "payment-view-page-payment",
  data() {
    return {
      permission: {
        appSlug: "payment",
        actionData: {}
      },
      StringUtils: StringUtils,
      payment_status: Constants.payment_status,
      type_payments: Constants.payment_types,
      criteria: {
        search: "",
        orderBy: "",
        ascDesc: "",
        orderDate: null,
        deliveryDate: null,
        paymentDate: null,
        storeFilters: [],
        statusFilters: []
      },
      paging: {
        page: 1,
        limit: Config.pagination.limit,
        pages: Config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0,
        pageSizes: []
      },
      items: [],
      item: {},
      onSearching: false
    };
  },
  methods: {
    async getFilter(){ 
      await this.getFilterGroups()
    },
    async onEditHandler(id) {
      this.SessionStorageUtils.setSession("page-payment", id);
      this.$router.push(`/payment/edit/${id}`);
    },
    onIssueHandler(data) {
      this.$refs.dialogIssue.initIssue(2,data.id);
      this.$bvModal.show("dialog_issue");
    },
    onConfirmHander(data) {
      let _this = this;
      this.AlertUtils.confirm(
        "info",
        `ต้องการที่จะยืนยันการชำระเงินนี้หรือไม่ ?`,
        async function() {
          const result = await _this.HttpServices.putData(
            `/ab_payment/updateConfirm/${data.id}`
          );
          if (result && result.status.code == "200") {
            _this.AlertUtils.alert("success", `บันทึกสำเร็จ`);
            _this.initialData();
          } else {
            _this.AlertUtils.alert("warning", result.status.message);
          }
        }
      );
    },
    

    /******* local function ******/
    changeLimit() {
      this.getListItems();
    },
    changePage(page) {
      this.paging.page = parseInt("" + page);
      this.getListItems();
    },
    sortHandler(orderBy) {
      if (orderBy != this.criteria.orderBy) {
        this.criteria.ascDesc = "asc";
      } else {
        this.criteria.ascDesc = this.criteria.ascDesc == "asc" ? "desc" : "asc";
      }
      this.criteria.orderBy = orderBy;
      this.getListItems();
    },
    searchHandler() {
      this.paging.page = 1;
      this.getListItems();
    },
    async getListItems() {
      let filters = [];
      this.criteria.storeFilters.map(v => {
        if (v.selected) {
          filters.push(v.id);
        }
      });
      filters = filters.join(",");

      let statusFilters = [];
      this.criteria.statusFilters.map(v => {
        if (v.selected) {
          statusFilters.push(v.id);
        }
      });
      statusFilters = statusFilters.join(",");
      let orderDate = "";
      let deliveryDate = "";
      let paymentDate = "";
      if (this.criteria.orderDate) {
        orderDate = DateUtils.dateFormat(this.criteria.orderDate, "YYYY-MM-DD");
      }
      if (this.criteria.deliveryDate) {
        deliveryDate = DateUtils.dateFormat(this.criteria.deliveryDate,"YYYY-MM-DD");
      }

      if (this.criteria.paymentDate) {
        paymentDate = DateUtils.dateFormat(this.criteria.paymentDate,"YYYY-MM-DD");
      }
      let params = `search=${this.criteria.search}&storeFilters=${filters}&statusFilters=${statusFilters}&orderDate=${orderDate}&deliveryDate=${deliveryDate}`;
      params += `&paymentDate=${paymentDate}`;
      params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
      this.onSearching = true;
      const result = await this.HttpServices.getData(`/ab_payment?${params}`);
      this.onSearching = false;
      if (result && result.status.code == "200") {
        const data = result.data;
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.pageTotal = total;
        this.paging.rowTotal = data.rowTotal;
        this.items = data.data;
        this.paging.pageSizes = [];
        for (let i = 1; i <= total; i++) {
          this.paging.pageSizes.push({ id: i, text: i });
        }
      } else {
        this.paging.pageSizes = [];
        this.paging.rowTotal = 0;
        this.items = [];
      }
    },
    checkDisabledDelete() {
      let resp = true;
      for (const v of this.items) {
        if (v.selected) {
          resp = false;
          break;
        }
      }
      return resp;
    },
    countFilter() {
      const filters = this.criteria.storeFilters.filter(v => v.selected);
      const filters1 = this.criteria.statusFilters.filter(v => v.selected);
      return filters.length + filters1.length;
    },
    unSelectFilter(item) {
      item.selected = false;
      this.searchHandler();
    },
    unSelectAllFilter() {
      this.criteria.storeFilters.map(v => {
        v.selected = false;
      });
      this.criteria.statusFilters.map(v => {
        v.selected = false;
      });
      this.searchHandler();
    },
    /******* local function ******/
    async getFilterGroups() {
      const result = await this.HttpServices.getData(
        `/ab_payment/getCountFilter`
      );
      const statusFilters = result.data.statusFilters;
      this.criteria.storeFilters = result.data.storeFilters;

      const paymentStatus = Constants.payment_status;

      this.criteria.statusFilters = [];
      paymentStatus.forEach(v => {
        let lst = null;
        statusFilters.forEach(vv => {
          if (vv.id == v.id) {
            lst = vv;
          }
        });
        let filterCount = 0;
        if (lst) {
          filterCount = lst.filter_count;
        }
        this.criteria.statusFilters.push({
          id: v.id,
          name: v.text,
          filter_count: filterCount
        });
      });
    },
    async initialData() {
      this.getListItems();
      // this.getFilterGroups();
    },
    clickCredit() {
      $(document).ready(function() {
        document.paymentRequestForm.submit();
      });
    },
    async onDownloadReceipt(data) {
      const result = await this.HttpServices.getDataFile(
        `/ab_ebilling/order/${data.id}?docType=receipt`
      );
      if (result !== null) {
        const blob = new Blob([result], {
          type: "application/pdf"
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `receipt.pdf`);
        document.body.appendChild(link);
        link.click();
      }
    }
  },
  components: {
    dialogIssue
  },
  async mounted() {
    await this.PermissionServices.viewPermission(
      this.permission.appSlug,
      this.$router
    );
    this.permission.actionData = await this.PermissionServices.getPermission(
      this.permission.appSlug
    );
    await this.SessionStorageUtils.deleteSession("page-payment");
    await this.initialData();
  }
};
</script>
<style scope>
.checkbox_style > .custom-control {
  left: 12px;
}
.spinner {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top-color: #000;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}
</style>
